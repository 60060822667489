import {
  BroadcastIcon,
  FullScreenExitIcon,
  FullScreenIcon,
  MenuDropdown,
} from '@hakimo-ui/hakimo/ui-elements';
import { FilmIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { CamFeedMode } from '../../../types/common';
import { EventHistory } from '../../../types/event';
import CameraTimeline from '../cam-controls/CameraTimeline';
import { getTenantColor } from '../utils';
import { getCamFeedMenuItems } from './util';

interface Props {
  eventHistory: EventHistory[];
  camFeedMode: CamFeedMode;
  onChangeCamFeedMode: (val: CamFeedMode) => void;
  camName: string;
  tenantName: string;
  onUpdatePlaybackSrc: (params: string) => void;
  isFullScreen: boolean;
  toggleFullscreen: () => void;
  showSeeker: boolean;
  currentFrameId: string;
  elaborateLiveMode?: boolean;
  cameraTimeZone: string;
}

export function CamControls(props: Props) {
  const {
    eventHistory,
    camFeedMode,
    onChangeCamFeedMode,
    camName,
    tenantName,
    onUpdatePlaybackSrc,
    isFullScreen,
    toggleFullscreen,
    showSeeker,
    currentFrameId,
    elaborateLiveMode,
    cameraTimeZone,
  } = props;

  const menuButton =
    camFeedMode === CamFeedMode.EVENT_VIDEO ? (
      <FilmIcon className="text-ondark-text-1 h-4 w-4 rounded-lg fill-white" />
    ) : (
      <div className="flex animate-pulse items-center gap-1 text-red-500">
        <BroadcastIcon
          className={clsx(
            'h-4 w-4 rounded-lg fill-white',
            camFeedMode === CamFeedMode.LIVE ? 'bg-red-500' : 'bg-gray-500'
          )}
        />
        {elaborateLiveMode && <span className="text-xs">Live</span>}
      </div>
    );

  return (
    <div className="from-dark-surface absolute right-0 bottom-0 left-0 cursor-pointer bg-gradient-to-t">
      {showSeeker && (
        <CameraTimeline
          eventHistory={eventHistory}
          currentFrameId={currentFrameId}
          onUpdatePlaybackSrc={onUpdatePlaybackSrc}
          timezone={cameraTimeZone}
        />
      )}
      <div className="flex items-center justify-between px-1 py-[1px]">
        <MenuDropdown
          position="top"
          items={getCamFeedMenuItems(camFeedMode, onChangeCamFeedMode)}
          menuButton={menuButton}
          isMinimized
          theme="dark"
        />
        <span
          title={tenantName}
          className="truncate text-xs"
          style={{ color: getTenantColor(tenantName || '') }}
        >
          {tenantName}
        </span>
        <span
          title={camName}
          className="truncate text-xs"
          style={{ color: getTenantColor(tenantName || '') }}
        >
          {camName}
        </span>
        <button
          onClick={toggleFullscreen}
          className="rounded-md focus:ring-offset-0 enabled:hover:bg-slate-800/50"
        >
          {isFullScreen ? (
            <FullScreenExitIcon className="fill-dark-text h-5 w-5" />
          ) : (
            <FullScreenIcon className="fill-dark-text h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
}

export default CamControls;
