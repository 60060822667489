import { Alert, Selectable } from '@hakimo-ui/shared/ui-base';

import { useCreateScanEscalation } from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import { toast, useFullscreen, useUser } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EscalationState } from '../../types/common';
import { VisionOutboundType } from '../../types/event';
import CamsMonitor, { CamsMonitorRef } from './CamsMonitor';

interface Props {
  visionTenants: Selectable[];
}

export function ScanMonitoring(props: Props) {
  const { visionTenants } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const camsMonitorRef = useRef<CamsMonitorRef>(null);
  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);
  const [showWsCloseAlert, setShowWsCloseAlert] = useState(false);
  const [escalationState, setEscalationState] = useState<EscalationState>({
    escalationId: '',
    groupId: '',
    locationId: '',
    cameraId: '',
    tenantId: '',
    createdTimestamp: 0,
  });
  const {
    data,
    isSuccess,
    mutate: createEscalation,
    isError,
    error,
  } = useCreateScanEscalation();
  const user = useUser();

  useEffect(() => {
    if (isSuccess) {
      data &&
        setEscalationState((prev) => ({
          ...prev,
          escalationId: data.escalation_id,
        }));
      toast('Incident escalated successfully');
    }
  }, [data, isSuccess]);

  const handleEscalation = (camera: Camera, groupId: string) => {
    // TODO: Think of a batter way to handle it as SOP is moved to Investigation view
    const cameraId = camera.id;
    const locationId = String(camera.location?.id) || '';
    createEscalation({
      camera_id: cameraId,
      location_id: locationId,
    });
    setEscalationState((prev) => ({
      ...prev,
      cameraId,
      groupId,
      locationId,
      tenantId: camera.tenantId || '',
      createdTimestamp: Date.now(),
    }));
  };

  const onResolveEscalation = useCallback(
    (resolutionComment: string, groupId: string) => {
      // send message in websocket once escalation is resolved
      const camsMonitor = camsMonitorRef.current;
      camsMonitor?.sendMessageInWebSocket({
        camera_id: escalationState.cameraId,
        group_id: groupId,
        event_type: VisionOutboundType.ESCALATION_CLOSE,
        tenant_id: escalationState.tenantId,
        additional_data: {
          username: user.email,
          escalation_open_timestamp: escalationState.createdTimestamp, // add escalation open timestamp
          event_timestamp: Date.now(),
          escalation_id: escalationState.escalationId,
          resolution_status: resolutionComment ?? 'cancelled',
        },
      });

      setEscalationState({
        cameraId: '',
        groupId: '',
        escalationId: '',
        locationId: '',
        tenantId: '',
        createdTimestamp: 0,
      });
    },
    [escalationState, user.email]
  );

  const onShowWsCloseMessage = useCallback(() => setShowWsCloseAlert(true), []);

  if (showWsCloseAlert) {
    return (
      <Alert className="m-4" type="error">
        Scan connection terminated. This may be due to a connection error or
        login from another location. Refresh to use Scan in this window.
      </Alert>
    );
  }

  return (
    <div
      ref={containerRef}
      className={clsx(
        'bg-onlight-bg-1 dark:bg-ondark-bg-1 fixed bottom-0 left-0 right-0 flex overflow-hidden p-4',
        isFullScreen ? 'top-0' : 'top-20'
      )}
    >
      {isError && (
        <Alert className="m-4" type="error">
          {error.message}
        </Alert>
      )}
      <div className="flex-grow">
        <CamsMonitor
          ref={camsMonitorRef}
          visionTenants={visionTenants}
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          onEscalate={handleEscalation}
          onCloseWs={onShowWsCloseMessage}
          onErrorWs={onShowWsCloseMessage}
          escalationState={escalationState}
          onResolveEscalation={onResolveEscalation}
        />
      </div>
    </div>
  );
}
