import { Camera } from '@hakimo-ui/hakimo/types';
import { Alert, Button, FullViewModal } from '@hakimo-ui/shared/ui-base';
import { useCallback, useMemo, useState } from 'react';
import { CamAction, CamFeedMode, EscalationState } from '../../../types/common';
import { EventHistory } from '../../../types/event';
import ScanSOP from '../../scan-sop/ScanSOP';
import MultiCameraTimeline from '../cam-controls/MultiCameraTimeline';
import CamFeed from '../cam-feed/CamFeed';
import GlobalCamActions from './GlobalCamActions';

interface Props {
  groupId: string;
  cameras: Camera[];
  onClose: () => void;
  handleCamAction: (actionType: CamAction) => () => void;
  camsEventHistoryMap: Record<string, EventHistory[]>;
  onCreateEscalation: () => void;
  escalationState: EscalationState;
  onResolveEscalation: (comment?: string) => void;
}

export function InvestigationView(props: Props) {
  const {
    groupId,
    cameras,
    onClose,
    handleCamAction,
    camsEventHistoryMap,
    onCreateEscalation,
    escalationState,
    onResolveEscalation,
  } = props;

  const firstCam = cameras[0];
  const [globalCamsState, setGlobalCamsState] = useState<{
    mode: CamFeedMode;
    playbackParams: string;
  }>({
    mode: CamFeedMode.EVENT_VIDEO,
    playbackParams: '',
  });

  const onSafe = useCallback(() => {
    handleCamAction(CamAction.SAFE)();
    onClose();
  }, [handleCamAction, onClose]);

  const [rowCount, colCount] = useMemo(() => {
    const totalCams = cameras.length;
    const rows = Math.ceil(totalCams / 2);
    const cols = totalCams < 2 ? 1 : 2;
    return [rows, cols];
  }, [cameras.length]);

  const handleGlobalPlaybackSrc = (params: string) =>
    setGlobalCamsState({ mode: CamFeedMode.PLAYBACK, playbackParams: params });

  const updateGlobalCamsMode = (mode: CamFeedMode) =>
    setGlobalCamsState({ ...globalCamsState, mode });

  const isOngoingEscalation =
    escalationState.groupId === groupId && escalationState.escalationId !== '';

  return (
    <FullViewModal open={true} onClose={onClose} title="Investigation View">
      <div className="flex gap-2">
        <div
          className="grid h-[calc(100vh-5.5rem)] w-3/4 grid-cols-2 gap-2 p-2"
          style={{
            gridTemplateRows: `repeat(${rowCount}, minmax(0, 1fr))`,
            gridTemplateColumns: `repeat(${colCount}, minmax(0, 1fr))`,
          }}
        >
          {cameras.map(
            (cam) =>
              cam && (
                <CamFeed
                  key={cam.id}
                  name={cam.name}
                  cameraTimeZone={cam.location.timezone ?? 'UTC'}
                  tenantName={cam.tenantId || ''}
                  livestreamUrl={cam.livestreamUrl || ''}
                  playbackUrl={cam.visionPlaybackUrl}
                  eventHistory={camsEventHistoryMap[cam.id] || []}
                  globalCamsState={globalCamsState}
                  preloadLiveFeed
                  elaborateFrames
                />
              )
          )}
          <div className="col-span-2 flex items-center gap-2">
            <div className="flex-1">
              <MultiCameraTimeline
                cameras={cameras}
                camsEventHistoryMap={camsEventHistoryMap}
                updatePlaybackSrc={handleGlobalPlaybackSrc}
                mode={globalCamsState.mode}
              />
            </div>
            <div className="border-onlight-line-3 dark:border-ondark-line-3 rounded-md border p-[2px]">
              <GlobalCamActions
                mode={globalCamsState.mode}
                onChangeMode={updateGlobalCamsMode}
              />
            </div>
          </div>
        </div>
        <div className="flex h-[calc(100vh-8rem)] w-1/4 flex-col items-center justify-between gap-4">
          {isOngoingEscalation && (
            <Alert type="warning">
              Escalation in progress. Investiage & resolve through SOP
            </Alert>
          )}
          <ScanSOP
            onResolveEscalation={onResolveEscalation}
            escalationId={
              escalationState.groupId === groupId
                ? escalationState.escalationId
                : undefined
            }
            locationId={String(firstCam?.location.id) ?? ''}
            tenantId={firstCam.tenantId ?? ''}
            escalatedCamId={firstCam?.id ?? ''}
            createEscalation={onCreateEscalation}
          />
          {!isOngoingEscalation && (
            <Button variant="success" onClick={onSafe}>
              Safe
            </Button>
          )}
        </div>
      </div>
    </FullViewModal>
  );
}

export default InvestigationView;
