import { User } from '@hakimo-ui/hakimo/types';
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import { currentTenantAtom } from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { getTenantFilterItems } from './util';

interface Props {
  user: User;
}

function TenantFilter(props: Props) {
  const { user } = props;
  const [currentTenant, setCurrentTenant] = useAtom(currentTenantAtom);

  const allTenants = useMemo(() => {
    return getTenantFilterItems(user);
  }, [user]);

  const [tenantItems, setTenantItems] = useState<Selectable[]>(allTenants);

  const onChangeQuery = (query: string) => {
    const updateTenantsList = allTenants.filter((tenant) =>
      tenant.name.toLowerCase().includes(query.toLowerCase())
    );
    setTenantItems(updateTenantsList);
  };

  return (
    <Select
      getItems={() => tenantItems}
      value={currentTenant}
      onChange={(val) => val && setCurrentTenant(val)}
      displayValue={(item) => item?.name ?? ''}
      onChangeQuery={onChangeQuery}
      placeholder="Select a tenant"
    />
  );
}

export default TenantFilter;
