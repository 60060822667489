import { BroadcastIcon } from '@hakimo-ui/hakimo/ui-elements';
import { FilmIcon } from '@heroicons/react/24/outline';
import { CamFeedMode } from '../../../types/common';

export const getCamFeedMenuItems = (
  currentVal: CamFeedMode,
  onChange: (val: CamFeedMode) => void
) => {
  const onClick = (type: CamFeedMode) => () => onChange(type);
  const className = 'text-primary-500 dark:text-primary-500';

  return [
    {
      name: 'LIVE',
      onClick: onClick(CamFeedMode.LIVE),
      className: currentVal === CamFeedMode.LIVE ? className : '',
      icon: (
        <BroadcastIcon className="h-4 w-4 animate-pulse rounded-lg bg-red-500 fill-white" />
      ),
    },
    {
      name: 'Event Video',
      onClick: onClick(CamFeedMode.EVENT_VIDEO),
      className: currentVal === CamFeedMode.EVENT_VIDEO ? className : '',
      icon: <FilmIcon className="h-4 w-4" />,
    },
  ];
};
