import { useCallback, useEffect, useRef, useState } from 'react';
import { FrameData } from '../../../types/common';
import { formatTime } from '../utils';

interface Props {
  frames: Array<FrameData>;
  currentFrameIndex: number;
  fps: number;
  cameraTimeZone: string;
  loop?: boolean;
  onComplete?: () => void;
  width?: number | string;
  height?: number | string;
  className?: string;
  onFrameChange: (idx: number) => void;
  elaborateFrames?: boolean;
}

export function FramePlayer(props: Props) {
  const {
    frames,
    currentFrameIndex,
    cameraTimeZone,
    fps,
    loop = true,
    onComplete,
    width = '100%',
    height = '100%',
    className = '',
    onFrameChange,
    elaborateFrames,
  } = props;

  const [isPlaying, setIsPlaying] = useState(true);
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();
  const frameDuration = 1000 / fps;

  // Animation loop using requestAnimationFrame
  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current === undefined) {
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
        return;
      }

      const deltaTime = time - previousTimeRef.current;

      if (deltaTime >= frameDuration) {
        let nextFrame = currentFrameIndex + 1;
        if (nextFrame >= frames.length) {
          if (loop) {
            nextFrame = 0;
          } else {
            setIsPlaying(false);
            onComplete && onComplete();
            nextFrame = currentFrameIndex;
          }
        }
        onFrameChange(nextFrame);
        previousTimeRef.current = time;
      }
      requestRef.current = requestAnimationFrame(animate);
    },
    [
      currentFrameIndex,
      frameDuration,
      frames.length,
      loop,
      onComplete,
      onFrameChange,
    ]
  );

  // Start or stop the animation based on isPlaying
  useEffect(() => {
    if (isPlaying) {
      requestRef.current = requestAnimationFrame(animate);
    } else if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = undefined;
      previousTimeRef.current = undefined;
    }

    // Cleanup on unmount
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isPlaying, animate]);

  return (
    <div
      className={`frame-player relative flex items-center justify-center ${className}`}
      style={{ width, height }}
    >
      {frames.length > 0 ? (
        <>
          {elaborateFrames && (
            <span className="bg-ondark-bg-2 text-ondark-text-1 absolute top-0 right-0 rounded text-xs">
              {formatTime(
                new Date(frames[currentFrameIndex].createdTime),
                cameraTimeZone,
                true
              )}
            </span>
          )}
          <img
            src={frames[currentFrameIndex].frame}
            alt={`Frame ${currentFrameIndex + 1}`}
            style={{ objectFit: 'contain', height: '100%' }}
          />
        </>
      ) : (
        <p className="text-ondark-text-1 absolute inset-0 flex animate-pulse items-center justify-center text-xs">
          Events loading...
        </p>
      )}
    </div>
  );
}

export default FramePlayer;
