import { TrackingEvent } from '@hakimo-ui/hakimo/types';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useRef } from 'react';
import { MenuItem, MenuItemGroup } from '../types';

interface Props {
  items: MenuItem[];
  menuButton: React.ReactNode | string;
  menuButtonTrackingEvent?: TrackingEvent;
  position?: 'top' | 'bottom';
  theme?: 'system' | 'dark';
  isMinimized?: boolean;
}

export function MenuDropdown(props: Props) {
  const {
    menuButton,
    items,
    menuButtonTrackingEvent,
    position = 'bottom',
    theme = 'system',
    isMinimized,
  } = props;

  let groupedItems: MenuItemGroup = {};
  const menuButtonRef = useRef<HTMLDivElement>(null);
  groupedItems = items.reduce((groups, item) => {
    const groupName = item.group || 'default';
    groups[groupName] = groups[groupName] || [];
    groups[groupName].push(item);
    return groups;
  }, groupedItems);

  return (
    <Menu as="div" className="relative">
      <Menu.Button as="div" ref={menuButtonRef}>
        {isMinimized ? (
          menuButton
        ) : (
          <Button
            variant="icon"
            onSideEffect={
              menuButtonTrackingEvent && eventTracker(menuButtonTrackingEvent)
            }
          >
            {menuButton}
          </Button>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            bottom:
              position === 'top'
                ? menuButtonRef?.current?.clientHeight
                : undefined,
          }}
          className={clsx(
            'absolute z-10 mt-2 w-auto origin-top-right divide-y rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            position === 'top' ? 'left-0' : 'right-0',
            theme === 'dark'
              ? 'bg-dark-bg divide-gray-600 ring-gray-600'
              : 'dark:bg-dark-bg divide-gray-100 bg-white dark:divide-gray-600 dark:ring-gray-600'
          )}
        >
          {Object.entries(groupedItems).map(([groupKey, groupItems]) => (
            <div key={groupKey}>
              {groupItems.map(
                (option) =>
                  !option.hidden && (
                    <Menu.Item
                      key={option.name}
                      data-testid={option.dataTestId}
                    >
                      {({ active }) => (
                        <div
                          onClick={option.onClick}
                          className={clsx(
                            active ? 'bg-gray-100 hover:dark:bg-gray-700' : '',
                            'dark:text-dark-text dark:bg-dark-bg block text-gray-700',
                            'group flex cursor-pointer items-center whitespace-nowrap',
                            option.className ?? '',
                            isMinimized ? 'p-1 text-xs' : 'py-2 px-4 text-sm',
                            theme === 'dark'
                              ? 'bg-dark-bg text-dark-text hover:bg-gray-700'
                              : ''
                          )}
                        >
                          <span
                            className={clsx('mr-4', !option.icon && 'hidden')}
                          >
                            {option.icon || null}
                          </span>
                          <span>{option.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  )
              )}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default MenuDropdown;
