/* eslint-disable max-lines */
import { useSOP, useUpdateScanEscalation } from '@hakimo-ui/hakimo/data-access';
import { AudioRecorder } from '@hakimo-ui/hakimo/feature-shared';
import {
  ScanTenantSOP,
  TwilioLogType,
  UpdateEscalationRequest,
} from '@hakimo-ui/hakimo/types';
import { LoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { toast, trackEvent } from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { useEffect, useRef, useState } from 'react';
import { DispatchType } from '../../types/sop';
import { ResolveAction } from '../common/ResolveAction';
import DispatchView from './DispatchView';
import SOPInfo from './SOPInfo';

interface Props {
  onResolveEscalation: (comment?: string) => void;
  escalationId?: string;
  locationId: string;
  tenantId: string;
  escalatedCamId: string;
  createEscalation?: () => void;
}

export function ScanSOP(props: Props) {
  const {
    onResolveEscalation,
    escalationId,
    locationId,
    escalatedCamId,
    createEscalation,
  } = props;
  const [scanSOP, setScanSOP] = useState<ScanTenantSOP | undefined>(undefined);
  const updateTypeRef = useRef('StatusUpdate');
  const resolutionCommentRef = useRef('');

  const {
    data: sopData,
    isLoading: isSOPLoading,
    isError: isLoadSOPError,
    error: loadSOPError,
  } = useSOP({ locationId });

  const [dispatchType, setDispatchType] = useState<DispatchType | undefined>(
    undefined
  );

  const onSuccess = () => {
    if (updateTypeRef.current === 'StatusUpdate') {
      toast('Escalation resolved successfully');
      const resolutionComment = resolutionCommentRef.current;
      onResolveEscalation(resolutionComment);
      resolutionCommentRef.current = '';
    }
  };

  const { mutate, isError, error, isLoading } = useUpdateScanEscalation(
    escalationId || '',
    onSuccess
  );

  useEffect(() => {
    if (sopData && sopData.scan_sop) {
      setScanSOP(sopData.scan_sop);
    }
  }, [sopData]);

  const onDispatchPD = () => {
    setDispatchType(DispatchType.PD);
    if (!escalationId && createEscalation) {
      createEscalation();
    }
  };
  const onDispatchGuard = () => {
    setDispatchType(DispatchType.GUARD);
    if (!escalationId && createEscalation) {
      createEscalation();
    }
  };
  const onCancelDispatch = () => setDispatchType(undefined);
  const getDispatchContacts = () => {
    return dispatchType === DispatchType.PD
      ? scanSOP?.emergencyContact
      : scanSOP?.nonEmergencyContact;
  };

  const onResolve = (message: string) => {
    updateTypeRef.current = 'StatusUpdate';
    resolutionCommentRef.current = message;
    const payload: UpdateEscalationRequest = {
      escalation_id: escalationId ?? '',
      resolution_comment: message,
      status: 'Resolved',
    };
    mutate(payload);
  };

  const onTalkdownCompleted = (camId: string, scanEscalationId?: string) => {
    trackEvent('talkdown_submitted_for_escalation', {
      cameraId: camId,
      escalationId: scanEscalationId,
    });
  };

  const logTwilioCall = (twilioCallSid: string, toName: string) => {
    updateTypeRef.current = 'TwilioLog';
    const payload: UpdateEscalationRequest = {
      escalation_id: escalationId ?? '',
      twilio_log_type: TwilioLogType.TWILIO_CALLS,
      twilio_ssid: twilioCallSid,
      twilio_calling_to_name: toName,
      update_type: 'Call SMS logs',
    };
    mutate(payload);
  };

  return (
    <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 relative mt-2 max-h-full min-h-[30rem] overflow-auto rounded p-4">
      <h2 className="text-center font-semibold">
        Standard Operating Procedure (SOP)
      </h2>
      {isError && <Alert type="error">{error.message}</Alert>}
      {isLoadSOPError && <Alert type="error">{loadSOPError.message}</Alert>}
      {isSOPLoading && (
        <div className="my-4 flex h-full w-full items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
      {!dispatchType && scanSOP && (
        <>
          <SOPInfo
            address={scanSOP.address}
            emergencySituations={scanSOP.emergencySituations}
            exceptions={scanSOP.exceptions}
            nonEmergencySituations={scanSOP.nonEmergencySituations}
          />
          {scanSOP.isTalkdownEnabled && (
            <div className="dark:border-ondark-line-2 border-onlight-line-3 my-4 flex items-center justify-between rounded-md border p-2">
              <span className="text-sm">Perform talkdown:</span>
              <AudioRecorder
                cameraId={escalatedCamId}
                escalationId={escalationId}
                onTalkdown={onTalkdownCompleted}
              />
            </div>
          )}
          <div className="my-4 flex items-center justify-around">
            <Button onClick={onDispatchPD} variant="error">
              Dispatch PD
            </Button>
            <Button onClick={onDispatchGuard} variant="outline">
              Notify Security
            </Button>
          </div>
        </>
      )}
      {dispatchType && scanSOP && (
        <DispatchView
          address={scanSOP.address}
          contacts={getDispatchContacts() || []}
          onCancel={onCancelDispatch}
          onCallInitiated={logTwilioCall}
        />
      )}
      {scanSOP && escalationId && (
        <div className="my-12">
          <ResolveAction onResolve={onResolve} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default ScanSOP;
