import { BroadcastIcon } from '@hakimo-ui/hakimo/ui-elements';
import { ItemsSwitch, SwitchItem } from '@hakimo-ui/shared/ui-base';
import { FilmIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { CamFeedMode } from '../../../types/common';

interface Props {
  mode: CamFeedMode;
  onChangeMode: (val: CamFeedMode) => void;
}

export function GlobalCamActions(props: Props) {
  const { onChangeMode, mode } = props;

  const switchOptions = [
    {
      id: CamFeedMode.LIVE,
      name: 'Live',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      icon: (iconProps: any) => (
        <BroadcastIcon
          {...iconProps}
          className={clsx(
            'rounded-lg fill-white',
            mode === CamFeedMode.LIVE
              ? 'animate-pulse bg-red-500'
              : 'bg-gray-500'
          )}
        />
      ),
    },
    {
      id: CamFeedMode.EVENT_VIDEO,
      name: 'Event Video',
      icon: FilmIcon,
    },
  ];

  const onChangeItemsSwitch = (item: SwitchItem) =>
    onChangeMode(item.id as CamFeedMode);

  return (
    <ItemsSwitch
      items={switchOptions}
      onChange={onChangeItemsSwitch}
      selectedItem={switchOptions.find((opt) => opt.id === mode)}
      orientation="vertical"
      size="small"
    />
  );
}

export default GlobalCamActions;
