import { UpdateEscalationRequest } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateScanEscalation(
  escalationId: string,
  onSuccess?: () => void
) {
  const url = `/v2/orm/escalations/${escalationId}`;
  const request = new Request(url, {
    method: 'PUT',
  });

  return useAuthenticatedMutation<null, UpdateEscalationRequest>(request, {
    onSuccessInvalidationKeys: [['scan-escalations']],
    onSuccess,
  });
}

export default useUpdateScanEscalation;
