import clsx from 'clsx';
import { JSXElementConstructor, ReactNode } from 'react';

export interface SwitchItem {
  id: string;
  name: string;
  icon?: JSXElementConstructor<{ className: string }>;
  disabled?: boolean;
  extra?: ReactNode;
}

interface Props {
  items: SwitchItem[];
  selectedItem?: SwitchItem;
  onChange: (item: SwitchItem) => void;
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium';
}

export function ItemsSwitch(props: Props) {
  const {
    items,
    selectedItem,
    onChange,
    orientation = 'horizontal',
    size = 'medium',
  } = props;

  const onItemClick = (id: string) => () => {
    const target = items.find((item) => item.id === id);
    target && onChange(target);
  };

  return (
    <nav
      className={clsx('flex gap-2', orientation === 'vertical' && 'flex-col')}
      aria-label="Tabs"
    >
      {items.map((item) => {
        const IconComp = item.icon;
        return (
          <button
            key={item.id}
            onClick={onItemClick(item.id)}
            className={clsx(
              selectedItem?.id === item.id
                ? 'bg-primary-200 text-primary-700 dark:bg-primary-800 dark:text-primary-200'
                : 'text-onlight-text-3 hover:text-onlight-text-2 dark:text-ondark-text-2 dark:hover:text-ondark-text-1',
              'flex items-center gap-2 rounded-md',
              size === 'medium' && 'px-3 py-2 text-sm font-medium',
              size === 'small' && 'p-1 text-xs font-normal'
            )}
          >
            {IconComp && <IconComp className="h-5 w-5" />}
            {item.name}
            {item.extra}
          </button>
        );
      })}
    </nav>
  );
}
