/* eslint-disable max-lines */
import { Camera } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import {
  CamAction,
  CamGroupDetails,
  EscalationState,
} from '../../../types/common';
import { EventHistory } from '../../../types/event';
import CamFeed from '../cam-feed/CamFeed';
import InvestigationView from './InvestigationView';

interface Props {
  groupId: string;
  camGroupDetails: CamGroupDetails;
  eventCamsSet?: Set<string>;
  onGroupAction: (
    cameraId: string,
    groupId: string,
    actionType: CamAction
  ) => void;
  camsEventHistoryMap: Record<string, EventHistory[]>;
  escalationState: EscalationState;
  onResolveEscalation: (comment?: string) => void;
}

export function CamGroup(props: Props) {
  const {
    camGroupDetails,
    eventCamsSet,
    groupId,
    onGroupAction,
    camsEventHistoryMap,
    escalationState,
    onResolveEscalation,
  } = props;

  const [shownCamIndex, setShownCamIndex] = useState(0);
  const [showInvestigationModal, setShowInvestigationModal] = useState(false);

  const eventCams = useMemo(() => {
    return eventCamsSet ? Array.from(eventCamsSet) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCamsSet, eventCamsSet?.size]);

  const selectedCam: Camera | undefined = useMemo(() => {
    const camId = eventCams[shownCamIndex];
    const allCameras = camGroupDetails.cameras;
    return allCameras.find((c) => c.id === camId);
  }, [eventCams, camGroupDetails.cameras, shownCamIndex]);

  const handleCamAction = (actionType: CamAction) => () => {
    onGroupAction(selectedCam?.id ?? 'unknown-cam-id', groupId, actionType);
  };

  const onClickLeft = () =>
    shownCamIndex > 0 && setShownCamIndex(shownCamIndex - 1);

  const onClickRight = () =>
    shownCamIndex < eventCams.length - 1 && setShownCamIndex(shownCamIndex + 1);

  const activeEventCameras: Camera[] = useMemo(
    () =>
      eventCams
        .map((camId) => {
          const allCameras = camGroupDetails.cameras;
          return allCameras.find((c) => c.id === camId);
        })
        .filter((cam) => cam !== undefined) as Camera[],
    [eventCams, camGroupDetails.cameras]
  );

  const handleModalClose = () => setShowInvestigationModal(false);
  const openInvestigationModal = () => {
    onGroupAction(selectedCam?.id ?? '', groupId, CamAction.INVESTIGATE);
    setShowInvestigationModal(true);
  };
  const handleResolveEscalation = (comment?: string) => {
    handleModalClose();
    onResolveEscalation(comment);
  };

  return (
    <div
      className={clsx(
        'relative max-h-full max-w-full flex-grow',
        escalationState.groupId === groupId && 'rounded-md bg-red-500'
      )}
    >
      <span
        title={camGroupDetails.groupName}
        className="text-ondark-text-1 from-dark-surface absolute top-[1px] left-[1px] z-10 w-2/3 truncate rounded-md bg-gradient-to-b p-[2px] text-xs"
      >
        {camGroupDetails.groupName}
      </span>
      <span className="text-ondark-text-1 from-dark-surface absolute top-[1px] right-[1px] z-10 rounded-md bg-gradient-to-b p-[2px] text-xs">{`${
        shownCamIndex + 1
      }/${eventCams.length}`}</span>
      {shownCamIndex > 0 && (
        <Button
          disabled={shownCamIndex < 1}
          variant="icon"
          onClick={onClickLeft}
          classNames="absolute left-1 top-[40%] z-10"
        >
          <ChevronLeftIcon className="h-3 w-3" />
        </Button>
      )}
      {shownCamIndex < eventCams.length - 1 && (
        <Button
          disabled={shownCamIndex >= eventCams.length - 1}
          variant="icon"
          onClick={onClickRight}
          classNames="absolute right-1 top-[40%] z-10"
        >
          <ChevronRightIcon className="h-3 w-3" />
        </Button>
      )}
      {activeEventCameras.map(
        (activeCam) =>
          activeCam && (
            <div
              key={activeCam.id}
              className={clsx(
                'flex items-center justify-center p-[1px]',
                activeCam.id === selectedCam?.id
                  ? 'h-full w-full'
                  : 'invisible h-0 w-0 opacity-0'
              )}
            >
              <CamFeed
                key={activeCam.id}
                name={activeCam.name}
                cameraTimeZone={activeCam.location.timezone ?? 'UTC'}
                tenantName={activeCam.tenantId || ''}
                livestreamUrl={activeCam.livestreamUrl || ''}
                playbackUrl={activeCam.visionPlaybackUrl}
                onClickSafe={
                  groupId !== escalationState.groupId
                    ? handleCamAction(CamAction.SAFE)
                    : undefined
                }
                onClickSnooze={
                  groupId !== escalationState.groupId
                    ? handleCamAction(CamAction.SNOOZE)
                    : undefined
                }
                onClickInvestigate={openInvestigationModal}
                eventHistory={camsEventHistoryMap[activeCam.id] || []}
                onDoubleClick={openInvestigationModal}
              />
            </div>
          )
      )}
      {showInvestigationModal && (
        <InvestigationView
          groupId={groupId}
          cameras={activeEventCameras}
          onClose={handleModalClose}
          handleCamAction={handleCamAction}
          camsEventHistoryMap={camsEventHistoryMap}
          escalationState={escalationState}
          onCreateEscalation={handleCamAction(CamAction.ESCALATE)}
          onResolveEscalation={handleResolveEscalation}
        />
      )}
    </div>
  );
}

export default CamGroup;
