import { TwilioCall } from '@hakimo-ui/hakimo/feature-shared';
import { ScanSOPContact } from '@hakimo-ui/hakimo/types';
import { draggableNodeAtom, eventTracker } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { Call } from '@twilio/voice-sdk';
import { useSetAtom } from 'jotai';

interface Props {
  address: string;
  contacts: ScanSOPContact[];
  onCancel: () => void;
  onCallInitiated: (twilioCallSid: string, toName: string) => void;
}

export function DispatchView(props: Props) {
  const { address, contacts, onCancel, onCallInitiated } = props;
  const setDraggableNode = useSetAtom(draggableNodeAtom);
  const onClose = () => {
    setDraggableNode(null);
  };

  const onCall = (contact: ScanSOPContact) => () => {
    const handleCall = (c: Call) => {
      if (c.parameters['CallSid']) {
        onCallInitiated(c.parameters['CallSid'], contact.name);
      }
    };
    // TODO: sending tenant id was giving error, resolve it
    setDraggableNode(
      <TwilioCall
        onCloseCb={onClose}
        toName={contact.name}
        toNumber={contact.phoneNumber}
        onCallInitiated={handleCall}
      />
    );
  };

  return (
    <div className="mt-2">
      <h2 className="my-4 flex items-center gap-2 font-semibold text-red-700/80 dark:text-red-400/80">
        <Button variant="icon" onClick={onCancel}>
          <ArrowLeftIcon className="h-5 w-5" />
        </Button>
        Escalate now to concerned authorities
      </h2>
      <div className="my-2 flex items-center gap-2">
        <span className="font-semibold">Address:</span>
        <span className="text-sm italic">{address}</span>
      </div>
      <div className="space-y-2">
        <div className="font-semibold">
          Mention below necessary details on the call:
        </div>
        <ol className="ml-4 list-decimal space-y-1 text-sm">
          <li>Location of the incident</li>
          <li>Your location and role (How do you know)</li>
          <li>Description of the individual(s) involved, if applicable</li>
          <li>Description of any vehicles involved, if applicable</li>
          <li>Situation assessment (e.g., trespassing, theft, etc.)</li>
          <li>Hakimo's SOC phone number for follow-ups: +1 (650) 600-6975</li>
        </ol>
      </div>
      <div className="dark:border-ondark-line-2 mt-8 flex max-h-60 flex-col gap-3 divide-y overflow-auto rounded-md border-2 px-4 py-2 dark:divide-white/10">
        {contacts.map((contact) => (
          <div
            key={`${contact.phoneNumber}-${contact.name}`}
            className="flex items-center justify-between pt-1"
          >
            <div className="flex flex-col gap-1 text-xs">
              <span className="text-sm">{contact.name}</span>
              <span>{contact.phoneNumber}</span>
              {contact.role && <span>({contact.role})</span>}
            </div>
            <Button
              variant="icon"
              onClick={onCall(contact)}
              onSideEffect={eventTracker('call_apartment_contact')}
            >
              <PhoneIcon className="text-primary-500 mr-2 h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DispatchView;
