import { Modifier } from '@dnd-kit/core';
import { RefObject } from 'react';

/**
 * Returns X offset with which component has to render
 * @param position
 * @param timelineRef
 * @returns
 */
export const getHoverTranslate = (
  position: number,
  timelineRef: RefObject<HTMLDivElement>
) => {
  if (!timelineRef.current || position === null) return '50%';

  const timelineWidth = timelineRef.current.offsetWidth;
  const imageWidth = timelineWidth / 2; // Assuming image width is half of timeline width
  const hoverX = (position / 100) * timelineWidth;

  if (hoverX < imageWidth / 2) {
    return '0%'; // Align to left edge
  } else if (hoverX > timelineWidth - imageWidth / 2) {
    return '100%'; // Align to right edge
  } else {
    return '50%'; // Center
  }
};

// will be used later when timeline will be extended
// to older time where playback is not supported
export const restrictToTimeline =
  (offsetX: number): Modifier =>
  ({ transform, draggingNodeRect, containerNodeRect }) => {
    const value = { ...transform, y: 0 };
    if (!draggingNodeRect || !containerNodeRect) {
      return value;
    }
    if (
      draggingNodeRect.left + transform.x <
      containerNodeRect.left + offsetX
    ) {
      value.x = containerNodeRect.left + offsetX - draggingNodeRect.left;
    } else if (
      draggingNodeRect.right + transform.x >=
      containerNodeRect.left + containerNodeRect.width
    ) {
      value.x =
        containerNodeRect.left +
        containerNodeRect.width -
        draggingNodeRect.right;
    }

    return value;
  };

export function getTruncatedText(
  text: string,
  containerRef: RefObject<HTMLElement>
): string {
  if (!containerRef.current) return text;
  // Get the width of the container
  const containerWidth = containerRef.current.offsetWidth;
  // Approximate width of a single character in pixels
  const charWidth = 7;
  // Calculate how many characters can fit in the container
  const maxChars = Math.floor(containerWidth / charWidth);
  if (text.length <= maxChars) {
    // No truncation needed
    return text;
  }
  // Calculate how many characters to show at the start and end
  const halfChars = Math.floor(maxChars / 2);

  return `${text.slice(0, halfChars)}...${text.slice(-halfChars)}`;
}
