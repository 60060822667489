interface Props {
  address: string;
  exceptions: string[];
  emergencySituations: string[];
  nonEmergencySituations: string[];
}

export function SOPInfo(props: Props) {
  const { address, emergencySituations, exceptions, nonEmergencySituations } =
    props;
  return (
    <div className="mt-2 space-y-4">
      <div className="flex items-center gap-4">
        <span className="font-semibold">Address:</span>
        <span className="text-sm italic">{address}</span>
      </div>

      <h3 className="font-semibold">Emergency Situations:</h3>
      <ul className="ml-4 list-inside list-disc text-sm">
        {emergencySituations.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>

      <h3 className="font-semibold">Non - Emergency Situations:</h3>
      <ul className="ml-4 list-inside list-disc text-sm">
        {nonEmergencySituations.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>

      <h3 className="font-semibold">Exceptions:</h3>
      <ul className="ml-4 list-inside list-disc text-sm">
        {exceptions.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default SOPInfo;
