import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';
import Button from '../button/Button';

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  closable?: boolean;
  title?: string;
}

export function FullViewModal({
  open,
  onClose,
  children,
  closable = true,
  title,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => null} // preventing close from outside click or escape button
        static={true}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-4 z-50 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300 transform"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition ease-in duration-200 transform"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <Dialog.Panel
              className={`dark:bg-dark-bg dark:text-dark-text relative h-full w-full max-w-none overflow-auto rounded-md bg-white p-3`}
            >
              {closable && (
                <div className="absolute top-2 right-2">
                  <Button
                    variant="icon"
                    onClick={onClose}
                    aria-label="Close modal"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600" />
                  </Button>
                </div>
              )}
              {title && <div className="h-6 font-semibold">{title}</div>}

              <div className="mt-2">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default FullViewModal;
