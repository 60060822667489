import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';

interface Props {
  seekerPosition: number | null;
  containerHeight: number;
  containerWidth: number;
}

export function Seeker(props: Props) {
  const { seekerPosition, containerHeight, containerWidth } = props;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'scan-multi-cam-timeline-seeker',
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    left: seekerPosition ?? containerWidth,
    height: `${containerHeight + 4}px`,
    zIndex: 30,
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
      }}
      {...listeners}
      {...attributes}
      className={clsx('absolute -top-2 translate-x-[-5px] cursor-pointer')}
    >
      <svg width={10} height={containerHeight + 4}>
        <g>
          <line
            x1="0"
            y1="0"
            x2="10"
            y2="0"
            strokeWidth="4"
            className="stroke-onlight-primary"
          />
          <line
            x1="5"
            y1="2"
            x2="5"
            y2={containerHeight + 8}
            className="stroke-onlight-primary"
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
}

export default Seeker;
