import {
  BellSnoozeIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';

interface Props {
  onClickSafe?: () => void;
  onClickSnooze?: () => void;
  onClickInvestigate?: () => void;
  hideActionText?: boolean;
}

export function CamFeedActions(props: Props) {
  const { onClickInvestigate, onClickSafe, onClickSnooze, hideActionText } =
    props;
  return (
    <div className="absolute top-1/2 left-1/2 hidden -translate-x-1/2 -translate-y-1/2 group-hover/feed:block">
      <div className="flex gap-2">
        {onClickSafe && (
          <FeedActionButton
            icon={ShieldCheckIcon}
            text="Safe"
            onClick={() => onClickSafe?.()}
            hideText={!!hideActionText}
          />
        )}
        {onClickSnooze && (
          <FeedActionButton
            icon={BellSnoozeIcon}
            text="Snooze"
            onClick={() => onClickSnooze?.()}
            hideText={!!hideActionText}
          />
        )}
        {onClickInvestigate && (
          <FeedActionButton
            icon={MagnifyingGlassIcon}
            text="Investigate"
            onClick={() => onClickInvestigate?.()}
            hideText={!!hideActionText}
          />
        )}
      </div>
    </div>
  );
}

interface FeedActionButtonProps {
  text: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  onClick: () => void;
  hideText?: boolean;
}

function FeedActionButton(props: FeedActionButtonProps) {
  const { text, onClick, icon, hideText } = props;

  const ActionIcon = icon;

  return (
    <button
      onClick={onClick}
      title={text}
      className="flex items-center gap-1 rounded bg-black/50 px-3 py-1 text-xs font-semibold text-white transition-colors hover:bg-black/80"
    >
      <ActionIcon className="h-5 w-5 text-white" />
      {!hideText && text}
    </button>
  );
}

export default CamFeedActions;
